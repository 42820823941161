import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { ConfigContext } from '../../src/store/ConfigContext'
import { ErrorContext } from '../../src/store/ErrorContext'
import { generateError } from '../../src/utils/helpers'
import {
  getParsedProductName,
  getProductFamily
} from '../../src/utils/modelNameParser'
import {
  EMPTY_FUNCTION,
  DEVICE_NOT_SUPPORTED,
  DEVICE_LEDM_ONLY,
  DHP_DISCOVERY_TREE_EMPTY_ERROR,
  DEVICE_LEDM_AND_CDM
} from '../../src/config/constants'
import usePrinter from '../../src/hooks/usePrinter'
import { SERVICE_ID } from '../config/constants'
import { LoggingContext } from '../../src/store/LoggingContext'

export const PrinterContext = React.createContext({
  discoveryTree: null,
  productFamily: null,
  modelName: null,
  init: EMPTY_FUNCTION,
  isSupported: null
})

const PrinterProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const { setError, error } = useContext(ErrorContext)
  const [productFamily, setProductFamily] = useState('')
  const [modelName, setModelName] = useState('')
  const [discoveryTree, setDiscoveryTree] = useState(null)
  const [fetchedModel, setFetchedModel] = useState(false)
  const [resourceError, setResourceError] = useState(null)
  const { Logger } = useContext(LoggingContext)

  const fwProtocolCapability =
    sessionContext?.device?.fwProtocolCapability || DEVICE_NOT_SUPPORTED
  const isSupported =
    fwProtocolCapability === DEVICE_LEDM_ONLY ||
    fwProtocolCapability === DEVICE_LEDM_AND_CDM

  const { fetchDiscovery, fetchDeviceInfo } = usePrinter(
    isSupported,
    discoveryTree
  )

  /* Hook responsible for setting generic error in case unable to pull required
   * resources */
  useEffect(() => {
    if (resourceError && !error) {
      setError(generateError({ errorType: resourceError }, SERVICE_ID))
    }
  }, [resourceError, error, setError])

  /* Async hook callback for fetching model name */
  const fetchModel = useCallback(async () => {
    let _modelName = sessionContext?.device?.modelName
    if (!_modelName) {
      const { modelName } = await fetchDeviceInfo()
      _modelName = modelName
    }

    const nearestModelName = getParsedProductName(_modelName)
    const _productFamily = getProductFamily(nearestModelName)

    Logger.log(`model name - ${nearestModelName}`)
    Logger.log(`product family - ${_productFamily}`)

    setProductFamily(_productFamily)
    setModelName(nearestModelName)
  }, [sessionContext?.device?.modelName, fetchDeviceInfo, Logger])

  /* Hook responsible for fetching model name once discovery tree is available */
  useEffect(() => {
    if (discoveryTree && !fetchedModel && !modelName) {
      setFetchedModel(true)
      fetchModel()
    }
  }, [fetchedModel, discoveryTree, fetchModel, modelName, Logger])

  /* Init method is responsible for fetching discovery tree. This will trigger
   * other dependent hooks */
  const init = useCallback(async () => {
    if (!isSupported) return
    const _discoveryTree = await fetchDiscovery()
    if (!_discoveryTree) {
      Logger.warn('Unable to fetch discovery tree')
      setResourceError(DHP_DISCOVERY_TREE_EMPTY_ERROR)
      return
    }
    setDiscoveryTree(JSON.stringify(_discoveryTree))
  }, [fetchDiscovery, isSupported, Logger])

  const printerState = useMemo(
    () => ({
      discoveryTree,
      productFamily,
      modelName,
      init,
      isSupported
    }),
    [discoveryTree, productFamily, modelName, init, isSupported]
  )

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
