const NOVELLI_BASE = 'NovelliBase'
const NOVELLI_PLUS = 'NovelliPlus'
const TACCOLA = 'Taccola'
const TACCOLA_PLUS = 'TaccolaPlus'
const VASARI = 'Vasari'
const VASARI_PLUS = 'VasariPlus'

export const ELIGIBLE_DEVICES_CALIBRATION_ONLY = [
  NOVELLI_BASE,
  NOVELLI_PLUS,
  VASARI,
  VASARI_PLUS
]

export const ELIGIBLE_DEVICES = [
  ...ELIGIBLE_DEVICES_CALIBRATION_ONLY,
  TACCOLA,
  TACCOLA_PLUS
]

export const REMOVE_CARDBOARD_PAGE = 'cartridgeAlert'
export const SCALABLE_ERROR_PAGE = 'calibrationAlert'
export const INK_ALERTS = ['carriageJam', 'closeDoorOrCover']
export const CALIBRATION_ALERTS = ['jamInPrinter', 'inputTrayFailedToDisengage']

// Onboarding Director specific
export const SERVICE_ID = 'omig-jwv-remove-cardboard'
