import React, { useContext, useRef, useCallback, useMemo } from 'react'
import {
  EMPTY_FUNCTION,
  STATUS_COMPLETED,
  RESULT
} from '../../src/config/constants'
import useLedm from '../../src/hooks/useLedm'
import { PrinterContext } from './PrinterContext'
import {
  CALIBRATION_ALERTS,
  ELIGIBLE_DEVICES,
  ELIGIBLE_DEVICES_CALIBRATION_ONLY,
  INK_ALERTS,
  REMOVE_CARDBOARD_PAGE,
  SCALABLE_ERROR_PAGE,
  SERVICE_ID
} from '../config/constants'

export const ActionContext = React.createContext({
  isCurrentActionAuto: true,
  findNextPage: EMPTY_FUNCTION,
  state: []
})

const ActionProvider = (props) => {
  const { productFamily, discoveryTree } = useContext(PrinterContext)
  const { fetchInkAlerts, fetchCalibrationAlerts } = useLedm(SERVICE_ID)
  const actionState = useRef([])
  const actionsToExecute = useRef([
    {
      type: 'ink',
      alerts: INK_ALERTS,
      page: REMOVE_CARDBOARD_PAGE,
      applicable_products: ELIGIBLE_DEVICES
    },
    {
      type: 'calibration',
      alerts: CALIBRATION_ALERTS,
      page: SCALABLE_ERROR_PAGE,
      applicable_products: ELIGIBLE_DEVICES_CALIBRATION_ONLY
    }
  ])

  const findNextAction = useCallback(async () => {
    let nextAction = actionsToExecute.current.shift()

    if (!nextAction) return STATUS_COMPLETED

    if (!nextAction.applicable_products.includes(productFamily)) {
      actionState.current.push({
        name: nextAction.page,
        result: RESULT.INELIGIBLE
      })
      return findNextAction()
    }

    const _discoveryTree = JSON.parse(discoveryTree)
    const { category } =
      nextAction.type === 'ink'
        ? await fetchInkAlerts(_discoveryTree)
        : await fetchCalibrationAlerts(_discoveryTree)

    if (!category || !nextAction.alerts.includes(category)) {
      actionState.current.push({
        name: nextAction.page,
        result: RESULT.ALREADY_PERFORMED
      })
      return findNextAction()
    }

    return nextAction.page
  }, [discoveryTree, fetchCalibrationAlerts, fetchInkAlerts, productFamily])

  const findNextPage = useCallback(
    async (action = null, result = null) => {
      if (action && result) {
        actionState.current.push({ name: action, result: result })
      }
      const nextAction = await findNextAction()
      return `/${nextAction}`
    },
    [findNextAction]
  )

  const state = useMemo(
    () => ({
      findNextPage,
      isCurrentActionAuto: true,
      state: actionState.current
    }),
    [findNextPage]
  )

  return (
    <ActionContext.Provider value={state}>
      {props.children}
    </ActionContext.Provider>
  )
}

export default ActionProvider
