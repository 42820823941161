import React, { useContext } from 'react'
import { Route, Routes, useMatch } from 'react-router-dom'
import RemoveCardboard from './RemoveCardboard'
import { ConfigContext } from '../../../src/store/ConfigContext'

const App = () => {
  const { localization } = useContext(ConfigContext)
  const match = useMatch('/:country/:language/*')
  return (
    <Routes>
      <Route
        path={`${
          localization.enabled && match ? match.pathnameBase : ''
        }/remove-cardboard/*`}
        element={<RemoveCardboard />}
      />
    </Routes>
  )
}

export default App
