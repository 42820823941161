import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../../src/store/ConfigContext'
import { ANALYTICS, RESULT } from '../../src/config/constants'
import useAnalytics from '../../src/hooks/useAnalytics'
import { FullscreenProgress } from '../../src/components/LiveUi/FullscreenProgress'
import { ELIGIBLE_DEVICES } from '../config/constants'
import { ActionContext } from '../store/ActionContext'
import { PrinterContext } from '../store/PrinterContext'
import { useNavigate } from 'react-router-dom'

const { SCREENS } = ANALYTICS

const PortalEntry = () => {
  const navigate = useNavigate()
  const [printerInitiated, setPrinterInitiated] = useState(false)
  const [nextPageLoaded, setNextPageLoaded] = useState(false)
  const {
    sessionId,
    init: configInit,
    closeServiceInstance
  } = useContext(ConfigContext)
  const {
    productFamily,
    init: printerInit,
    isSupported
  } = useContext(PrinterContext)
  const { fireScreenDisplayed } = useAnalytics(
    SCREENS.START_REMOVE_CARDBOARD,
    true
  )
  const { findNextPage } = useContext(ActionContext)
  fireScreenDisplayed()

  useEffect(() => {
    if (!isSupported) {
      closeServiceInstance(RESULT.INELIGIBLE)
      return
    }
    configInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sessionId !== '' && !printerInitiated && isSupported) {
      setPrinterInitiated(true)
      printerInit()
    }
  }, [sessionId, printerInit, printerInitiated, isSupported])

  useEffect(() => {
    if (!nextPageLoaded && productFamily) {
      setNextPageLoaded(true)
      if (!ELIGIBLE_DEVICES.includes(productFamily)) {
        closeServiceInstance(RESULT.INELIGIBLE)
        return
      }
      findNextPage().then((route) => {
        route && navigate(`..${route}`)
      })
    }
  }, [
    productFamily,
    closeServiceInstance,
    findNextPage,
    nextPageLoaded,
    navigate
  ])

  return <FullscreenProgress />
}

export default PortalEntry
