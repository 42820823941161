import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Stack } from '@jarvis/web-stratus-client'
import ErrorProvider from '../../../src/store/ErrorContext'
import ConfigProvider from '../../../src/store/ConfigContext'
import PrinterProvider from '../../store/PrinterContext'
import ActionProvider from '../../store/ActionContext'
import LoggingProvider from '../../../src/store/LoggingContext'
import { Logger } from '../../utils/helpers'
import App from './App'
import { useShellRootContext } from '../../../src/contexts/ShellRoot'

const AppWrapper = ({ stack = Stack.pie }) => {
  const { t, interfaces = {} } = useShellRootContext?.() || {}
  const {
    v1: {
      analytics,
      navigation,
      store,
      localization,
      serviceRouting,
      monitoring
    }
  } = interfaces
  return (
    <Router>
      <LoggingProvider logger={Logger}>
        <ErrorProvider>
          <ConfigProvider
            navigation={navigation}
            analytics={analytics}
            store={store}
            stack={stack}
            localization={localization}
            serviceRouting={serviceRouting}
            monitoring={monitoring}
            closeServiceInstanceOutputKey="removeCarboardFeatures"
            t={t}
          >
            <PrinterProvider>
              <ActionProvider>
                <App />
              </ActionProvider>
            </PrinterProvider>
          </ConfigProvider>
        </ErrorProvider>
      </LoggingProvider>
    </Router>
  )
}

export default AppWrapper
