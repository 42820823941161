import { logWithPrefix } from '../../src/utils/helpers'

const LOG_PREFIX = 'RemoveCardboard'

export const Logger = {
  debug: (log) => logWithPrefix('debug', log, LOG_PREFIX),
  log: (log) => logWithPrefix('log', log, LOG_PREFIX),
  info: (log) => logWithPrefix('info', log, LOG_PREFIX),
  warn: (log) => logWithPrefix('warn', log, LOG_PREFIX),
  error: (log) => logWithPrefix('error', log, LOG_PREFIX)
}
